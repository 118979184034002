@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Fredericka+the+Great&family=Fugaz+One&family=Indie+Flower&family=Luckiest+Guy&display=swap');
canvas {
    position: fixed;
    top:0;
    left: 0;
}

body {
    --spacing: 350px;
    background-color: #88B46B;
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

main {
    scroll-snap-type: y mandatory;
    color: white;
    z-index: 99;
    display: grid;
    width:100%;
}

#bg {
    z-index: 3;
}

#gallery {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social-links {
    position: fixed;
    z-index: 99;
    bottom :0;
}

.section {
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: start;
    min-height:100vh;
    scroll-snap-stop: always;
    text-align: center;
}

.section p {
    width: 50%;
    margin: 0 auto;
    line-height: 2rem;
}

.section p a {
    color: #2e7c91;
}

#footer-section {
    background-image: url("/public/images/sapo.png");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: bottom;
}

#footer-heading {
    position: absolute;
    width: 100%;
    top: 10%;
}

.section-content {
    margin: 0;
    position: absolute;
    top: 50%;
    text-align: center;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

#stats {
    position: fixed;
    left: 50%;
    bottom: 5%;
    margin-left: -120px;
    width: 240px;
    text-align: center;
    font-size: x-large;
}

.marquee {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    background-color: #74963f;
    overflow: hidden;
    position: relative;
    height: 50px;
    width: 100%;
    border-top: 5px solid #282c34;
    border-bottom: 5px solid #282c34;
    /* Full width of its parent */
    white-space: nowrap;
}

.marquee p {
    position: absolute;
    width: auto;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    /* Start off the right edge */
    transform: translateX(100%);
    /* Animate to the left */
    animation: scroll-left 120s linear infinite;
}

@keyframes scroll-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

#version {
    position: fixed;
    z-index: 3;
    font-family: Roboto;
    /*background-color: rgba(0,0,0,0.5);*/
    padding:10px;
    bottom: 0;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    text-align: center;
    font-size: x-small;
}

#contract-address {
    position: fixed;
    z-index: 99;
    /*background-color: rgba(0,0,0,0.5);*/
    padding:10px;
    bottom: 40px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    text-align: center;
    font-size: large;
    background-color: rgba(0,0,0,0.5);
}

#holder-count {
    padding: 5px;
}

#level {
    padding: 5px;
}

#market-cap {
    padding: 5px;
}

#logo {
    text-align: center;
    margin: 20px;
}

#logo img {
    height: 50vh;
}

.links {
    font-size: x-large;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.link-item {
    display: block;
    text-align: center;
    width:120px;
}

.link-item img {
    width:50px;
    padding-top: 10px;
    display:block;
    margin: 0 auto;
}

#cmc img,#dextools img {
    width: 80px;
    padding-top: 20px;
}

.section-heading-large {
    color: white;
    font-size: 60px;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 20px;
}

.socials-container {
    display: inline-flex;
    justify-content: center;
    position:absolute;
    bottom: 10%;
    right: 10%;
}

#ticker-text {
    font-size: 120px;
    position: absolute;
    bottom: 40%;
    left: 10%;
    transform: rotate(-10deg);
}

a {
    text-decoration: underline;

}

.social-item {
    background-color: #69cdff;
    border-radius:10px;
    border:5px solid black;
    width:80px;
    height:80px;
    text-align: center;
    padding:5px;
    margin: 10px;
}

.social-item img {
    width:80px;
}

#ca {
    font-size:18px;
}

a {
    text-decoration: none;
    color: inherit;
}

.mobile-show {
    display:none;
}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
}
.reveal.active{
    transform: translateY(0);
    opacity: 1;
}

#contract-container {
    margin-bottom:40px;
    cursor: pointer;
}

#contract {
    font-size:small;
}

.contract {
    animation: glitch1 2.5s infinite;
    margin: 0;
    line-height: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.contract:nth-child(2) {
    color: #67f3da;
    animation: glitch2 2.5s infinite;
}

.contract:nth-child(3) {
    color: #f16f6f;
    animation: glitch3 2.5s infinite;
}

/*#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 1;
}*/

#loading {
    position: fixed;
    top: 50%;
    width: 100%;
    font-size: larger;
    text-align: center;
}

/*Keyframes*/

@keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.5deg, -0.9deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.8deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: skew(0.4deg, 1deg);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes glitch2 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(-2px, -3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(-5px, -2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(-5px, -1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(-2px, -6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

@keyframes glitch3 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(2px, 3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(5px, 2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(5px, 1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(2px, 6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }


}