@media only screen and (max-width: 900px) {

    section {
        grid-column: 1/12;
    }

    #logo {
        width: 100%;
        text-align: center;
        right: 0;
    }

    #ca {
        font-size: 14px;
    }

    .mobile-show {
        display: block;
    }

    main {
        width: 97%;
        left: 3%;
    }

    .section-heading-large {
        font-size: 40px;
    }

    #contract-container {
        margin: 20px;
    }

    .contract {
        font-size: 12px;
    }

    #version {
        width: auto;
        font-size: x-small;
    }

    #contract-address {
        font-size: small;
    }

    #contract {
        font-size: xx-small;
    }

    section {
        height: 80vh;
    }

    #overlay {
        background-color: rgba(0,0,0,0.5);
    }

    }